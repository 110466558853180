@import 'base/normalize.scss';
@import 'base/reset.scss';
@import 'base/styles.scss';

.bring {
  color: #f5f5f5; }

.bring3 {
  padding-top: 535px;
  text-align: right;
  display: none;
  padding-right: 15%; }

.bring2 {
  display: none; }

.tab1 {
  margin-left: 40px; }
.tab2 {
  margin-left: 80px; }


$color: black;


p, a {
  text-shadow: 0 0 8px rgba(lighten($color, 50%), 0.3);
  font-family: 'Oswald', sans-serif;
  font-smoothing: antialiased;
  margin: 0.8em 0;
  font-size: 18px;
  color: $color; }

.ghost,
.glitch {
  opacity: 0.25; }

#message {
  margin-top: -75px;
  position: absolute;
  height: 260px;
  width: 100%;
  top: 50%;
  padding: 0 20%;
  letter-spacing: 2px;
  z-index: -5000; }

#animate {
  $color: darken(desaturate($color, 10%), 30%);
  text-shadow: 0 0 8px rgba(lighten($color, 50%), 0.1);
  position: absolute;
  color: $color;
  bottom: 0; }

#container {
  $color1: #20202D;
  $color2: #101018;
  $color3: #060612;
  background: -webkit-radial-gradient(50% 50%, ellipse, $color1 0%, $color2 75%, $color3 100%);
  background: -moz-radial-gradient(50% 50%, ellipse, $color1 0%, $color2 75%, $color3 100%);
  //position: absolute
  //height: 100%
  //width: 100%
  //left: 0
  top: 0;

  &:before {
    pointer-events: none;
    position: absolute;
    height: 100%;
    width: 100%;
    content: '';
    left: 0;
    top: 0; } }


@media (max-width: 1000px) {
  #message {
    margin-top: -175px !important; } }

