$main: white;
$back: #EF303B;
$red: #EF303B;
$white: #fff;

body {
  background-color: #EF303B;
}

.blurtext{
  position: relative;
  width: auto;
  text-align: center;
  font-size: 4vw;
  height: 10vw;
  text-transform: uppercase;
  font-family: 'Oswald', sans-serif;
  font-weight: 600;
  padding-top: 20% !important;;
  z-index: -4000;
  color: #EF303B;
  .color {
    color:black;
  }
  span , span2 {
    position: absolute;
    display: block;
    width: 100%;
    text-align: center;
    opacity: 0;
    transform: perspective(100px) translateZ(-15px);
    filter: blur(10px);
    letter-spacing: 0.1em;
    &.active{
      opacity: 1;
      transform: perspective(100px) translateZ(0px);
      filter: blur(0px);
      letter-spacing: 0.15em;
      transition: opacity 100ms linear, transform 1000ms linear, filter 0ms linear, letter-spacing 1000ms linear;
    }
    &.past{
      opacity: 0;
      transform: perspective(100px) translateZ(10px);
      filter: blur(10px);
      letter-spacing: 0.2em;
      transition: opacity 100ms linear, transform 1000ms linear, filter 0ms linear, letter-spacing 1000ms linear;
    }
  }
}



@mixin perspective($pixels) {
  -webkit-perspective: $pixels;
  -moz-perspective: $pixels;
  -o-perspective: $pixels;
  perspective: $pixels;
}

@mixin transition($speed) {
  -webkit-transition: -webkit-transform $speed;
  -moz-transition: -moz-transform $speed;
  -o-transition: -o-transform $speed;
  transition: transform $speed;
}

@mixin transform-style() {
  -webkit-transform-style: preserve-3d;
  -moz-transform-style: preserve-3d;
  -o-transform-style: preserve-3d;
  transform-style: preserve-3d;
}

@mixin transform($degrees) {
  -webkit-transform: rotateX( $degrees + deg);
  -moz-transform: rotateX( $degrees + deg);
  -o-transform: rotateX( $degrees + deg);
  transform: rotateX( $degrees + deg);
}

@mixin backface-visibility() {
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -o-backface-visibility: hidden;
  backface-visibility: hidden;
}

.text-info {
  padding: 5% 10% 0;
  letter-spacing: 2px;
}
.logo img {
  width: 10%;
}
.text-info, .text-info a {
  display: inline-grid;
  color: grey;
  font-size: 14px;
  margin: 0;
}
.cont-contactInfo {
  float: left;
  padding-right: 5%;
  width: 45%;

  h3 {
    font-size: 28px;
  }

  h4 {
    font-size: 22px;
    font-weight: normal;
    text-transform: uppercase;
  }

  h4.section {
    margin: 30px 0 0;
  }

  p {
    margin: 0;
  }

  p > a {
    text-transform: uppercase;
    font-weight: bold;
  }

  .social-icons {
    list-style: none;
    margin: 5px 0 0;
    padding: 0;

    li {
      float: left;
      margin-right: 3px;

      a {
        background-image: url("../images/sprite_social_icons.png");
        background-repeat: no-repeat;
        display: block;
        height: 28px;
        width: 29px;
      }

      a:hover {
        opacity: .8;
      }

      a.icon_twitter {
        background-position: -33px 0;
      }

      a.icon_linkedin {
        background-position: -68px 0;
      }

      a.icon_youtube {
        background-position: -102px 0;
      }

      a.icon_pinterest {
        background-position: -135px 0;
      }

      a.icon_vimeo {
        background-position: -169px 0;
      }

      a.icon_instagram {
        background-position: -204px 0;
      }
    }
  }
}
.right {
  float: right;
  width: 50%;
  padding: 0;
}
.cont-contactBtn {
  height: 575px;
  margin-top: 0;
  width: 100%;
  padding: 0;
  position: relative;
  float: left;

  @include perspective(800px);

  .cont-flip {
    position: absolute; 
    @include transition(.5s);
    @include transform-style();
    height: 100%;
    width: 100%;

    .front, .back {
      display: block;
      margin: 0;
      
      width: 100%;
      position: absolute;

      @include backface-visibility;
    }

    .front {
      background-color: transparent;
      height: 50%;
    }

    .back {
      background-color: $white;
      @include transform(180);
      height: 100%;
    }
  }

  .btn {
    margin-top: 155px;
  }

  .cont-flip.flipped  {
    @include transform(180);
  }

  .contact-form {
    padding: 0 10%;
    margin-top: 50px;
    width: 80%;

    input, textarea {
      border: 1px solid #808080;
      font-size: 12px;
      padding: 10px 0;
      text-transform: uppercase;
    }

    input[type="text"] {
      float: left;
      margin-bottom: 8px;
      padding-left: 3%;
      width: 46.9%;
    }

    .gutter {
      margin-right: 2.8%;
    }

    textarea {
      height: 106px;
      padding-left: 3%;
      margin-bottom: 22px;
      width: 96.2%;
    }

    .loading{
      display: none;
    }
    button.send {
      background-color: $red;
      border: none;
      color: $white;
      height: 45px;
      width: 100%;
      max-width:93px;
      text-align: center;
    }

  }

  .close {
    display: block;
    height: 19px;
    width: 19px;
    background-image: url('https://i.imgur.com/WoKg12q.png');
    background-repeat: no-repeat;
    background-position: center;
    position: absolute;
    top: 15px;
    right: 3%;
  }
}

.btn {
  border: 3px $white solid;
  color: $white;
  display: block;
  font-size: 15px;
  font-weight: normal;
  margin: 0 auto;
  max-width: 280px;
  padding: 15px 0;
  text-align: center;
  text-transform: uppercase;
  text-decoration: none;
  width: 100%;
  border-radius: 0px;
}




/*flip2*/
.cont-contactBtn2 {
  height: 359px;
  margin-top: 0;
  width: 50%;
  padding: 0;
  position: relative;
  float: left;

  @include perspective(800px);

  .cont-flip2 {
    position: absolute; 
    @include transition(.5s);
    @include transform-style();
    height: 100%;
    width: 100%;

    .front, .back {
      display: block;
      margin: 0;
      height: 100%;
      width: 100%;
      position: absolute;

      @include backface-visibility;
    }

    .front {
      background-color: transparent;
    }

    .back {
      background-color: white;
      @include transform(180);
    }
  }

  .btn {
    margin-top: 155px;
  }

  .cont-flip2.flipped2  {
    @include transform(180);
  }

  .contact-form {
    padding: 0 10%;
    margin-top: 50px;
    width: 80%;

    input, textarea {
      border: 1px solid #808080;
      font-size: 12px;
      padding: 10px 0;
      text-transform: uppercase;
    }

    input[type="text"] {
      float: left;
      margin-bottom: 8px;
      padding-left: 3%;
      width: 44.9%;
    }

    .gutter {
      margin-right: 2.8%;
    }

    textarea {
      height: 106px;
      padding-left: 3%;
      margin-bottom: 22px;
      width: 96.2%;
    }

    input[type="submit"] {
      background-color: $red;
      border: none;
      color: $white;
      height: 45px;
      width: 100%;
      max-width:93px;
      text-align: center;
    }

  }

  .close2 {
    display: block;
    height: 19px;
    width: 19px;
    background-image: url('https://i.imgur.com/WoKg12q.png');
    background-repeat: no-repeat;
    background-position: center;
    position: absolute;
    top: 15px;
    right: 3%;
  }
}

@media (max-width: 1000px) {
  .cont-contactBtn .btn, .cont-contactBtn2 .btn {
    margin-top: 55px;
  }
  #message {
    margin-top: -175 !important;
  }
}
@media (max-width: 850px) {
  #message {
    margin-top: -90px !important;
  }
}
@media (max-width: 600px) {
  .cont-contactBtn .btn, .cont-contactBtn2 .btn {
    width: 100%
  }
  .cont-contactBtn2 .cont-flip2 .back, .cont-contactBtn2 .cont-flip2 .front {
    width: 200%;
    margin-left: -100%;
  }
  .logo img {
    width: 25%;
    margin-top: -200px;
  }
  .cont-contactBtn {
    height: 615px;
    margin-bottom: 10px;
  }
}


